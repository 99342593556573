export const colors = {
  orange: "#e2211c",
  oranger: "#c05426",
  orangeDark: "#343434",
  lightestGrey: "#F5F5F5",
  lightGrey: "#CBCBCA",
  darkGrey: "#343434",
  darkGreyT: "rgba(52,52,52,.9)",
  shadow: "-22px 16px 17px rgba(0,0,0,.14)",
  titleDarkBig: "#343434",
  titleDarkSmall: "#343434",
  titleLightBig: "#666666",
  titleLightSmall: "#666666",
  whiteBg: "#FFFFFF",
}

// 16:9
const widthClients = 100
const heightClients = widthClients * 0.3291

export const aspectratio = {
  carouselWidthClients: widthClients + "vw",
  carouselHeightClients: heightClients + "vw",
}
