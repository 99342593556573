import React from "react"
import { Link } from "gatsby"
import Form from "./form"
import Logo from "../components/logo3"
import { FooterStyle } from "../styles/footer"
import OrangeBar from "../components/orangeBar"

const Footer = () => (
  <FooterStyle>
    <OrangeBar />
    <div className="container">
      <div className="logo">
        <Logo />
      </div>
      <div className="footerContent">
        <div className="address">
          <h5>Morada</h5>
          <p>Rua da Paz, Aveiro Park Empresarial, Arm. 16</p>
          <p>Quinta do Loureiro 3800-587 Cacia – Aveiro</p>
          <p>+351 917 081 867</p>
        </div>
        <div className="contacts">
          <h5 className="spanC">Contactos</h5>
          <p className="leftC">
            <a href="mailto:sicarpo@sicarpo.com"> sicarpo@sicarpo.com </a>
          </p>
          <p className="rightC">
            <a href="tel:+351917081867">+351 917 081 867</a>
          </p>
        </div>
        <div className="newsletter">
          <h5>Newsletter</h5>
          <Form formType="newsletter" />
        </div>
      </div>
      <div className="footerBottom">
        <div className="left">
          <span>
            © Sicarpo 2021, todos os direitos reservados - Design by INvisual
          </span>
        </div>
        <div className="center"></div>
        <div className="right">
          <Link to="/recrutamento">
            <span>Carreira</span>
          </Link>
          <a
            alt="Política de Privacidade"
            href="https://www.privacypolicygenerator.info/live.php?token=NxMnKMcPUKiDVfvhw4sjRjkCIPgxMXnJ"
            target="_blank"
          >
            <span>Política de Privacidade</span>
          </a>
        </div>
      </div>
    </div>
  </FooterStyle>
)

export default Footer
