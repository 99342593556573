import styled from "styled-components"
import { colors } from "./variables"

export const FooterStyle = styled.footer`
  background-color: ${colors.darkGrey};
  padding-bottom: 20px;
  z-index: 999;
  .logo {
    margin: 35px auto 0;
    text-align: center;
    width: min(30rem, 90vw);
  }

  .footerContent {
    margin: 35px auto 0 auto;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

    form {
      width: 100%;
    }
    .address,
    .contacts,
    .newsletter {
      text-align: center;
    }
    .address {
      display: grid;
      grid-template-rows: repeat(5, 1fr);
      row-gap: 10px;
    }

    .contacts {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 15px;
      row-gap: 10px;
    }
    /* .spanC {
      grid-column: span 2;
    }
    .leftC {
      text-align: left;
    } */

    h5 {
      font-family: "Monument", sans-serif;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 4px;
      font-weight: 500;
      font-size: 1.6rem;
    }

    p {
      color: ${colors.lightGrey};
      font-size: 1.4rem;
      line-height: 22px;
    }
  }
  .newsletter {
    align-items: center;
    align-content: center;
    display: grid;
    grid-template-rows: auto 1fr;
  }
  .form {
    width: 100%;
    box-sizing: border-box;
    padding: 5%;

    display: grid;
    grid-template-columns: minmax(0, 70%) minmax(0, auto);
    grid-column-gap: 25px;
    justify-content: center;
    align-content: center;
  }
  .form-input {
    width: 100%;
    text-indent: 15px;
    font-family: "Barlow", sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: left;
    color: ${colors.orange};
    background-color: ${colors.darkGrey};
    border: 1.2px solid #cbcbca;

    &:focus {
      outline: 0;
      background: none;
    }

    ::placeholder {
      color: #cbcbca;
    }
  }
  .form-button {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    border: none;
    text-align: center;
    background-color: ${colors.orange};
    color: ${colors.whiteBg};
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .icon-align {
    transform: translateX(2px);
    vertical-align: text-bottom;
  }

  .footerBottom {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;
    margin: 5% 15px 0;

    span {
      color: ${colors.lightGrey};
      font-size: 1.2rem;
    }

    .left,
    .right {
      white-space: nowrap;
    }

    .right {
      display: flex;
      justify-content: center;
      width: 100%;

      & > * {
        margin: 0 25px;
      }
    }
  }
  .modal-wrapper {
    display: grid;
    width: 100%;
    height: 100%;
  }
  .modal {
    justify-self: center;
    align-self: center;
    width: fit-content;
    font-family: "Barlow", sans-serif;
    font-size: 1.8rem;
    color: #ffffff;
  }

  /* ----------- Desktops and Laptops ----------- */
  @media only screen and (min-width: 1367px) and (max-width: 1824px) {
  }
  @media only screen and (min-width: 981px) and (max-width: 1366px) {
    .footerBottom {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media only screen and (min-width: 812px) and (max-width: 980px) {
    .footerContent p,
    .footerBottom span,
    .form-input {
      font-size: 1.2rem;
    }
    .form-input {
      text-indent: 12px;
    }
    .footerBottom {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media only screen and (max-width: 811px) {
    .footerContent {
      p {
        font-size: 1.2rem;
      }
      h5 {
        font-size: 1.2rem;
      }
    }
    .footerBottom {
      grid-template-columns: 1fr 1fr 1fr;
      span {
        font-size: 1.2rem;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .logo {
      margin: 5em auto 0 !important;
    }
    .footerContent {
      .address,
      .contacts {
        grid-row-gap: 2px;
      }
    }

    .footerBottom {
      margin: 2% auto 0 auto;

      span {
        font-size: 1rem;
      }
    }
    .form-input {
      font-size: 1.2rem;
    }
    .form-button {
      justify-self: right;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 0;

    .container {
      display: grid;
      grid-template-rows: minmax(0, 1fr) minmax(0, 3fr) minmax(0, auto);
      justify-content: center;
      align-content: center;

      & > * {
        margin: 0;
      }
    }

    .logo {
      align-self: center;

      svg {
        width: 80%;
      }
    }
    .footerContent {
      grid-template-rows: auto;
      grid-template-columns: none;
      align-items: center;
      padding: 7.5px 15px;

      .address {
        grid-template-rows: none;
      }
      .contacts {
        grid-template-columns: 1fr 1fr;
      }
      .address,
      .contacts,
      .newsletter {
        align-items: center;
        justify-items: center;
      }
      .leftC {
        justify-self: right;
      }
      .rightC {
        justify-self: left;
      }
      h5 {
        margin: 0;
      }
    }

    .footerBottom {
      padding: 7.5px 15px;

      .right {
        display: grid;
        grid-template-columns: none;
        grid-template-rows: repeat(3, 1fr);
        grid-row-gap: 10px;
        justify-self: right;
        justify-content: right;

        & > * {
          margin: 0 10px;
        }
      }
    }
  }

  @media screen and (max-width: 520px) {
    .footerBottom .left {
      white-space: normal;
    }
  }
`
