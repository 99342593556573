import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import Logo from "../components/logo"
import { HeaderStyle } from "../styles/header"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: true,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () =>
    this.setState(state => ({ visible: window.pageYOffset <= 55 }))

  render() {
    return (
      <HeaderStyle>
        <div
          className={classNames({
            logoNav: true,
            logoHidden: !this.state.visible,
          })}
        >
          <Link to="/">
            <Logo className="logoSvg" />
          </Link>
          <p className="logoInfo">
            sicarpo@sicarpo.com
            <br />
            +351 917 081 867
          </p>
        </div>
        <div
          className="navToggle"
          onClick={this.props.openSidebar}
          role="presentation"
        >
          <div className="hamburger">
            <span></span>
            <span></span>
            <span className="last"></span>
          </div>
        </div>
      </HeaderStyle>
    )
  }
}

export default Header
