// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clientes-js": () => import("./../../../src/pages/clientes.js" /* webpackChunkName: "component---src-pages-clientes-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-orcamento-js": () => import("./../../../src/pages/orcamento.js" /* webpackChunkName: "component---src-pages-orcamento-js" */),
  "component---src-pages-projetos-js": () => import("./../../../src/pages/projetos.js" /* webpackChunkName: "component---src-pages-projetos-js" */),
  "component---src-pages-recrutamento-js": () => import("./../../../src/pages/recrutamento.js" /* webpackChunkName: "component---src-pages-recrutamento-js" */),
  "component---src-pages-setor-civil-js": () => import("./../../../src/pages/setor-civil.js" /* webpackChunkName: "component---src-pages-setor-civil-js" */),
  "component---src-pages-setor-industrial-js": () => import("./../../../src/pages/setor-industrial.js" /* webpackChunkName: "component---src-pages-setor-industrial-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-proj-js": () => import("./../../../src/templates/proj.js" /* webpackChunkName: "component---src-templates-proj-js" */)
}

