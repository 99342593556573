import styled from "styled-components"
import { colors } from "./variables"

export const OverlayStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 102;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-out 0s, visibility 0.4s ease-out 0s;

  &.isOpen {
    opacity: 1;
    visibility: visible;
  }
`

export const NavbarStyle = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 77px;
  background: ${colors.darkGrey};
  z-index: 103;
  transform: translateX(100%);
  transition: all 0.3s ease;
  display: grid;
  grid-template-rows: 1fr 1fr;

  .mobile {
    display: none;
  }

  .sidebar {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-content: center;
    width: 100%;
    margin: 0 2vw;
  }

  .logoContainer {
    height: 70px;
    max-width: 150px;
    display: grid;
    align-items: center;
  }

  .logo {
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    -webkit-transition: opacity 0.5s ease;
  }
  .logoOpen {
    transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    -webkit-transition: opacity 0.5s ease;
    opacity: 1;
    transition-delay: 300ms;
  }
  .logoInfo {
    color: #ffffff;
    font-style: "Barlow", sans-serif;
    font-size: 1.4rem;

    display: grid;
    align-self: center;
    justify-self: right;
  }

  .nav-list-container {
    display: flex;
  }

  .nav-list {
    display: grid;
    grid-template-columns: repeat(10, auto);
    grid-column-gap: 0.25rem;
    margin-left: 10px;
    margin-right: 10px;
  }

  &.isOpen {
    transform: translateX(0%);
    display: flex;
    align-content: center;
  }

  &.isClosed {
    display: flex;
    align-content: center;
  }
  .activeLink {
    background-color: ${colors.orangeDark} !important;
    opacity: 1;
  }
  .navLink {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-family: "Barlow", sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0;
    transition: background-color 0.5s ease, opacity 0.5s ease;
    -o-transition: background-color 0.5s ease, opacity 0.5s ease;
    -moz-transition: background-color 0.5s ease, opacity 0.5s ease;
    -webkit-transition: background-color 0.5s ease, opacity 0.5s ease;

    padding: 0 10px;

    &:hover {
      background-color: ${colors.orangeDark};
    }
  }

  .navLinkOpen {
    transition: background-color 0.5s ease, opacity 0.5s ease;
    -o-transition: background-color 0.5s ease, opacity 0.5s ease;
    -moz-transition: background-color 0.5s ease, opacity 0.5s ease;
    -webkit-transition: background-color 0.5s ease, opacity 0.5s ease;
    opacity: 1;
    transition-property: background-color, opacity;
    transition-delay: 0ms, 300ms;
  }

  .navHidden {
    display: none;
  }

  .closeButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 72px;
    margin: 2.5px;
    position: absolute;
    right: 0;
    opacity: 0;
    transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;

    i {
      font-size: 4.5rem;
      color: #ffffff;
    }

    &:hover {
      opacity: 1;
      background-color: ${colors.orangeDark};
    }
  }

  /* Dropdown para a Bruna */
  /* Style The Dropdown Button */
  .dropbtn {
    white-space: nowrap;
    font-family: "Barlow", sans-serif;
    font-size: 1.28rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 1;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    padding: 0 10px;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s ease, opacity 0.5s ease;
    -o-transition: background-color 0.5s ease, opacity 0.5s ease;
    -moz-transition: background-color 0.5s ease, opacity 0.5s ease;
    -webkit-transition: background-color 0.5s ease, opacity 0.5s ease;
    padding: 0;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: ${colors.orange};
    top: 77px;
    min-width: 160px;
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: ${colors.whiteBg};
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    background-color: ${colors.orangeDark};
  }

  /* Show the dropdown menu on hover */
  /* .dropdown:hover .dropdown-content {
  display: block;
} */

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover {
    background-color: ${colors.orangeDark};
  }
  /* END - Dropdown * /

  /* ----------- Desktops and Laptops ----------- */
  @media only screen and (min-width: 2560px) {
    height: 100px;
    .navLink, .logoInfo {
      font-size: 2.2rem;
    }
  }
  @media only screen and (min-width: 1921px) and (max-width: 2559px) {
    height: calc(77px + 23 * (100vw - 1920px) / 640);
    .navLink, .logoInfo {
      font-size: calc(14px + 8 * (100vw - 1920px) / 640);
    }
  }

  @media only screen and (min-width: 1367px) and (max-width: 1824px) {
    .logo {
      width: 13rem;
    }
  }
  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    height: 70px;

    .logoContainer {
      height: 70px;

      .logo {
        width: 10rem;
      }
    }

    .logoInfo {
      font-size: 1.2rem;
      margin: 0;
    }
    .nav-list {
      grid-column-gap: 0.1rem;

      .navLink {
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
    .closeButton {
      display: none;
    }
    .dropbtn,
    .dropdown-content {
      font-size: 1.12rem;
    }
    .dropdown-content {
      min-width: 90px;
      top: 70px;
    }
  }

  /* ----------- Handhelds ----------- */
  @media only screen and (min-width: 981px) and (max-width: 1024px) {
    height: 65px;

    .logoContainer {
      height: 65px;
      .logo {
        width: 7rem;
      }
    }

    .logoInfo {
      font-size: 1.2rem;
      margin: 0;
    }
    .nav-list {
      grid-column-gap: 0.1rem;
      margin: 0 1%;

      .navLink {
        font-size: 1.2rem;
        font-weight: 600;
      }
      .navAnchor {
        display: auto;
      }
    }
    .closeButton {
      display: none;
    }
  }

  @media only screen and (min-width: 812px) and (max-width: 981px) {
    height: 65px;

    .sidebar {
      width: 100%;
      grid-template-columns: 20% 80%;
    }

    .logoContainer {
      height: 65px;
      .logo {
        width: 100%;
      }
    }

    .logoInfo {
      font-size: 1.2rem;
      margin: 0;
      display: none;
    }
    .nav-list-container {
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: 5%;
        background: linear-gradient(
          to left,
          rgba(0, 0, 0, 0),
          ${colors.orange} 80%,
          ${colors.orange} 100%
        );
        pointer-events: none;
        z-index: 1;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        height: 100%;
        width: 5%;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0),
          ${colors.orange} 80%,
          ${colors.orange} 100%
        );
        pointer-events: none;
      }
    }
    .nav-list {
      position: relative;
      grid-column-gap: 0.1rem;
      grid-template-columns: repeat(11, auto);
      width: 100%;

      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      .navLink {
        font-size: 1.2rem;
        font-weight: 600;
        padding: 0 10px;
      }
      .navAnchor {
        display: auto;
      }
      .navHidden {
        display: flex;
      }
      .dropdown {
        display: none;
      }
    }

    .navLink:nth-child(1) {
      margin-left: 25px;
    }
    .navLink:nth-last-child(1) {
      margin-right: 25px;
    }

    .closeButton {
      display: none;
    }
  }

  @media only screen and (max-width: 811px) {
    height: 55px;

    .sidebar {
      grid-template-columns: 20% 80%;
    }

    .logoContainer {
      height: 55px;
      .logo {
        width: 100%;
      }
    }

    .logoInfo {
      font-size: 1.2rem;
      margin: 0;
      display: none;
    }
    .nav-list-container {
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: 10%;
        background: linear-gradient(
          to left,
          rgba(0, 0, 0, 0),
          ${colors.orange} 80%,
          ${colors.orange} 100%
        );
        pointer-events: none;
        z-index: 1;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        height: 100%;
        width: 10%;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0),
          ${colors.orange} 80%,
          ${colors.orange} 100%
        );
        pointer-events: none;
      }
    }
    .nav-list {
      position: relative;
      grid-column-gap: 0.1rem;
      grid-template-columns: repeat(11, auto);
      width: 100%;

      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      .navLink {
        font-size: 1.2rem;
        font-weight: 600;
        padding: 0 10px;
      }
      .navAnchor {
        display: auto;
      }
      .navHidden {
        display: flex;
      }
      .dropdown {
        display: none;
      }
    }

    .navLink:nth-child(1) {
      margin-left: 25px;
    }
    .navLink:nth-last-child(1) {
      margin-right: 25px;
    }

    .closeButton {
      display: none;
    }
  }
`
