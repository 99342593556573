import React from "react"
import { Link } from "gatsby"
import Logo from "../components/logo3"
import { /* OverlayStyle, */ NavbarStyle } from "../styles/navbar"

const Navbar = props => (
  <>
    {/* <OverlayStyle className={props.isOpen ? 'isOpen' : 'isClosed'} onClick={props.closeSidebar} /> */}
    <NavbarStyle className={props.isOpen ? "isOpen" : "isClosed"}>
      <div className="sidebar">
        <div className="logoContainer" role="presentation">
          <Link
            to="/#inicio"
            onClick={props.removeHash}
            aria-label="Volte ao início"
          >
            <Logo className={props.isOpen ? "logoOpen logo" : "logo"} />
          </Link>
        </div>
        <div className="nav-list-container">
          <div className="nav-list">
            {/* <Link
              to="/#sobre"
              onClick={() => {
                props.removeHash()
              }}
              activeClassName="activeLink"
              className={props.isOpen ? "navLinkOpen navLink" : "navLink"}
            >
              Sobre
            </Link> */}
            <Link
              to="/setor-industrial"
              activeClassName="activeLink"
              className={props.isOpen ? "navLinkOpen navLink" : "navLink"}
            >
              <span>Setor Industrial</span>
            </Link>
            <Link
              to="/setor-civil"
              activeClassName="activeLink"
              className={props.isOpen ? "navLinkOpen navLink" : "navLink"}
            >
              <span>Setor Civil</span>
            </Link>
            {/* <Link
              to="/corte-laser"
              activeClassName="activeLink"
              className={props.isOpen ? "navLinkOpen navLink" : "navLink"}
            >
              <span>Corte Laser</span>
            </Link> */}
            {/* <Link
              to="/projetos"
              activeClassName="activeLink"
              className={props.isOpen ? "navLinkOpen navLink" : "navLink"}
            >
              <span>Projetos</span>
            </Link> */}
            {/* <Link
              onClick={props.removeHash}
              to="/#noticias"
              activeClassName="activeLink"
              className={
                props.isOpen
                  ? "navLinkOpen navLink navAnchor"
                  : "navLink navAnchor"
              }
            >
              <span>Notícias</span>
            </Link> */}
            {/* <Link
              to="/clientes"
              activeClassName="activeLink"
              className={props.isOpen ? "navLinkOpen navLink" : "navLink"}
            >
              <span>Clientes</span>
            </Link> */}
            {/* <Link
              onClick={props.removeHash}
              to="/#sobre"
              activeClassName="activeLink"
              className={
                props.isOpen
                  ? "navLinkOpen navLink navAnchor navHidden"
                  : "navLink navAnchor"
              }
            >
              <span>Sobre</span>
            </Link>
            <Link
              to="/historia"
              activeClassName="activeLink"
              className={
                props.isOpen ? "navLinkOpen navLink navHidden" : "navLink"
              }
            >
              <span>História</span>
            </Link> */}
            <Link
              to="/orcamento"
              activeClassName="activeLink"
              className={props.isOpen ? "navLinkOpen navLink" : "navLink"}
            >
              <span>Orçamentos</span>
            </Link>
            <Link
              to="/recrutamento"
              activeClassName="activeLink"
              className={props.isOpen ? "navLinkOpen navLink" : "navLink"}
            >
              <span>Carreira</span>
            </Link>
            <Link
              to="/contacto"
              activeClassName="activeLink"
              className={props.isOpen ? "navLinkOpen navLink" : "navLink"}
            >
              <span>Contactos</span>
            </Link>
          </div>
        </div>
        <p className="logoInfo">
          <a href="mailto:sicarpo@sicarpo.com">
            <span className="desktop">sicarpo@sicarpo.com</span>
            <span className="mobile">
              <i className="material-icons md-light">email</i>
            </span>
          </a>
          <a href="tel:+351917081867">
            <span className="desktop">+351 917 081 867</span>
            <span className="mobile">
              <i className="material-icons md-light">phone</i>
            </span>
          </a>
        </p>
        {/* <div className="closeButton" onClick={props.closeSidebar}><i className="material-icons">close</i></div> */}
      </div>
    </NavbarStyle>
  </>
)

export default Navbar
