import React from "react"
import { OrangeBarStyle } from "../styles/orangerBar"

const OrangeBar = props => (
  <OrangeBarStyle>
    <div></div>
  </OrangeBarStyle>
)

export default OrangeBar
