import styled from "styled-components"
import { colors } from "./variables"

export const HeaderStyle = styled.header`
  position: fixed;
  width: 100%;
  padding: 20px 2vw;
  box-sizing: border-box;
  z-index: 100;
  pointer-events: none;

  .logoNav {
    opacity: 1;
    transition: opacity 0.15s;
    pointer-events: auto;
    display: flex;

    svg {
      width: 15rem;
    }

    a {
      display: inline-block;
    }
  }

  .logoInfo {
    color: #ffffff;
    font-style: "Barlow", sans-serif;
    margin-left: auto;
    transform: translateX(-78px);
    font-size: 1.4rem;
  }

  .logoHidden {
    opacity: 0;
    pointer-events: none;
  }

  .navToggle {
    position: absolute;
    right: 0;
    top: 0;
    background-color: ${colors.orange};
    padding: 25px;
    z-index: 101;
    cursor: pointer;
    pointer-events: auto;

    .hamburger {
      span {
        width: 28px;
        height: 3px;
        background-color: #fff;
        display: block;
        margin-bottom: 6px;
      }
      .last {
        width: 17px;
      }
    }
  }

  @media screen and (max-width: 1366px) {
    display: none;
  }
`
