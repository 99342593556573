import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import qs from "qs"
import Arrow from "../images/arrow.svg"

const axios = require("axios")

const Form = props => {
  const [addedToContacto, setAddedToContacto] = useState(false)
  const [addedToRecrutamento, setAddedToRecrutamento] = useState(false)
  const [addedToBudget, setAddedToBudget] = useState(false)
  const [addedToNewsletter, setAddedToNewsletter] = useState(false)
  const [hasError, setHasError] = useState("")

  function showFileName() {
    let fileName = document.getElementById("userfile")
    let inputName = document.getElementById("cv")

    if (fileName.files.item(0)) {
      inputName.value = fileName.files.item(0).name
      inputName.defaultValue = "CV"
    }
  }

  function doContacto(e) {
    e.preventDefault()
    var formData = new FormData()
    formData.append("name", document.getElementById("name").value)
    formData.append("email", document.getElementById("email").value)
    formData.append("message", document.getElementById("message").value)

    axios
      .post("https://sicarpo.com/contacto.php", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data; charset=UTF-8",
        },
      })
      .then(res =>
        res.data === "success"
          ? (setAddedToContacto(true),
            setTimeout(function() {
              setAddedToContacto(false)
            }, 3000))
          : (setHasError(
              "Houve um erro inesperado. Tente novamente por favor."
            ),
            setTimeout(function() {
              setHasError("")
            }, 3000))
      )
  }

  function doRecrutamento(e) {
    e.preventDefault()
    var formData = new FormData()
    formData.append("name", document.getElementById("name").value)
    formData.append("email", document.getElementById("email").value)
    formData.append("message", document.getElementById("message").value)
    formData.append("city", document.getElementById("city").value)
    formData.append("userfile", document.getElementById("userfile").files[0])

    axios
    .post("https://sicarpo.com/recrutamento.php", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data; charset=UTF-8",
        },
      })
      .then(res =>
        res.data === "success"
          ? (setAddedToRecrutamento(true),
            setTimeout(function() {
              setAddedToRecrutamento(false)
            }, 3000))
          : (setHasError(
              "Houve um erro inesperado. Tente novamente por favor."
            ),
            setTimeout(function() {
              setHasError("")
            }, 3000))
      )
  }

  function doBudget(e) {
    e.preventDefault()
    var formData = new FormData()
    formData.append("empresa", document.getElementById("empresa").value)
    formData.append("email", document.getElementById("email").value)
    formData.append("contacto", document.getElementById("contacto").value)
    formData.append("message", document.getElementById("message").value)

    axios
    .post("https://sicarpo.com/orcamento.php", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data; charset=UTF-8",
        },
      })
      .then(res =>
        res.data === "success"
          ? (setAddedToBudget(true),
            setTimeout(function() {
              setAddedToBudget(false)
            }, 3000))
          : (setHasError(
              "Houve um erro inesperado. Tente novamente por favor."
            ),
            setTimeout(function() {
              setHasError("")
            }, 3000))
      )
  }

  function doNewsletter(e) {
    e.preventDefault()
    var data = {
      useremail: document.getElementById("email").value,
    }
    axios
    .post("https://sicarpo.com/newsletter.php", qs.stringify(data), {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then(res =>
        res.data === "success"
          ? (setAddedToNewsletter(true),
            setTimeout(function() {
              setAddedToNewsletter(false)
            }, 3000))
          : (setHasError(
              "Houve um erro inesperado. Tente novamente por favor."
            ),
            setTimeout(function() {
              setHasError("")
            }, 3000))
      )
  }

  return (
    <>
      {props.formType === "contacto" ? (
        <>
          {hasError ? (
            <div className="modal-wrapper">
              <ScrollAnimation
                animateIn="shake"
                offset={0}
                delay={0}
                className="modal"
              >
                {hasError}
              </ScrollAnimation>
            </div>
          ) : addedToContacto ? (
            <div className="modal-wrapper">
              <ScrollAnimation
                animateIn="bounceIn"
                offset={0}
                delay={0}
                className="modal"
              >
                Obrigado pelo contacto!
              </ScrollAnimation>
            </div>
          ) : (
            <form onSubmit={doContacto} autoComplete="false">
              <ScrollAnimation
                className="form"
                animateIn="fadeIn"
                offset={0}
                delay={0}
                animateOnce={true}
              >
                <label className="input">
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    offset={0}
                    delay={0}
                    animateOnce={true}
                  >
                    Nome
                    <input type="text" id="name" />
                  </ScrollAnimation>
                </label>
                <label className="input">
                  <ScrollAnimation
                    animateIn="fadeInRight"
                    offset={0}
                    delay={250}
                    animateOnce={true}
                  >
                    Email
                    <input type="email" id="email" />
                  </ScrollAnimation>
                </label>
                <label className="input-textarea">
                  <ScrollAnimation
                    animateIn="fadeIn"
                    offset={0}
                    delay={500}
                    animateOnce={true}
                  >
                    Mensagem
                    <textarea id="message" />
                  </ScrollAnimation>
                </label>
                <button className="tooltipButtonContainer">
                  <span className="tooltipButton">
                    Fale connosco <Arrow className="tooltipButtonArrow" />
                  </span>
                </button>
              </ScrollAnimation>
            </form>
          )}
        </>
      ) : props.formType === "recrutamento" ? (
        <>
          {hasError ? (
            <div className="modal-wrapper">
              <ScrollAnimation
                animateIn="shake"
                offset={0}
                delay={0}
                className="modal"
              >
                {hasError}
              </ScrollAnimation>
            </div>
          ) : addedToRecrutamento ? (
            <div className="modal-wrapper">
              <ScrollAnimation
                animateIn="bounceIn"
                offset={0}
                delay={0}
                className="modal"
              >
                Candidatura bem sucedida!
              </ScrollAnimation>
            </div>
          ) : (
            <form
              onSubmit={doRecrutamento}
              encType="multipart/form-data"
              method="POST"
              autoComplete="false"
            >
              <ScrollAnimation
                className="form"
                animateIn="fadeIn"
                offset={0}
                delay={0}
                animateOnce={true}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  offset={0}
                  delay={0}
                  animateOnce={true}
                >
                  <label className="input">
                    Nome
                    <input type="text" id="name" />
                  </label>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  offset={0}
                  delay={250}
                  animateOnce={true}
                >
                  <label className="input">
                    Email
                    <input type="email" id="email" />
                  </label>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  offset={0}
                  delay={500}
                  animateOnce={true}
                >
                  <label className="input">
                    Cidade
                    <input type="text" id="city" />
                  </label>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  offset={0}
                  delay={750}
                  animateOnce={true}
                >
                  <label className="input containerUpload">
                    Curriculum Vitae
                    <input type="text" id="cv" readOnly="readonly" />
                    <input
                      type="file"
                      name="userfile"
                      id="userfile"
                      placeholder="CV"
                      className="uglyUpload"
                      onChange={showFileName}
                    />
                    <input
                      type="hidden"
                      name="MAX_FILE_SIZE"
                      value="10000000"
                    />
                    <label htmlFor="userfile" className="iconUpload">
                      <i
                        className="material-icons-outlined"
                        style={{ cursor: `pointer` }}
                      >
                        cloud_upload
                      </i>
                    </label>
                  </label>
                </ScrollAnimation>
                <label className="input-textarea">
                  <ScrollAnimation
                    animateIn="fadeIn"
                    offset={0}
                    delay={1000}
                    animateOnce={true}
                  >
                    Observações
                    <textarea id="message" />
                  </ScrollAnimation>
                </label>
                <button className="tooltipButtonContainer">
                  <span className="tooltipButton">
                    Fale connosco <Arrow className="tooltipButtonArrow" />
                  </span>
                </button>
              </ScrollAnimation>
            </form>
          )}
        </>
      ) : props.formType === "orcamento" ? (
        <>
          {hasError ? (
            <div className="modal-wrapper">
              <ScrollAnimation
                animateIn="shake"
                offset={0}
                delay={0}
                className="modal"
              >
                {hasError}
              </ScrollAnimation>
            </div>
          ) : addedToBudget ? (
            <div className="modal-wrapper">
              <ScrollAnimation
                animateIn="bounceIn"
                offset={0}
                delay={0}
                className="modal"
              >
                Orçamento enviado com sucesso!
              </ScrollAnimation>
            </div>
          ) : (
            <form onSubmit={doBudget} method="POST" autoComplete="false">
              <ScrollAnimation
                className="form"
                animateIn="fadeIn"
                offset={0}
                delay={0}
                animateOnce={true}
              >
                {/* <ScrollAnimation
                  animateIn="fadeInLeft"
                  offset={0}
                  delay={0}
                  animateOnce={true}
                >
                  <label className="input">
                    Área
                    <select
                      id="area"
                      className="area"
                      placeholder="Área"
                      defaultValue="area"
                    >
                      <option value="area" disabled aria-label="Área"></option>
                      <option value="manutencao">Manutenção</option>
                      <option value="eletricidade">Eletricidade</option>
                      <option value="cortelaser">Corte Laser</option>
                    </select>
                  </label>
                </ScrollAnimation> */}
                <ScrollAnimation
                  animateIn="fadeInDown"
                  offset={0}
                  delay={250}
                  animateOnce={true}
                  className="stretch"
                >
                  <label className="input">
                    Empresa
                    <input type="text" id="empresa" />
                  </label>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  offset={0}
                  delay={500}
                  animateOnce={true}
                >
                  <label className="input">
                    Email
                    <input type="email" id="email" />
                  </label>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  offset={0}
                  delay={750}
                  animateOnce={true}
                >
                  <label className="input">
                    Contacto
                    <input type="tel" id="contacto" />
                  </label>
                </ScrollAnimation>

                <label className="input-textarea">
                  <ScrollAnimation
                    animateIn="fadeIn"
                    offset={0}
                    delay={1000}
                    animateOnce={true}
                  >
                    Mensagem
                    <textarea id="message" />
                  </ScrollAnimation>
                </label>
                <button className="tooltipButtonContainer">
                  <span className="tooltipButton">
                    Fale connosco <Arrow className="tooltipButtonArrow" />
                  </span>
                </button>
              </ScrollAnimation>
            </form>
          )}
        </>
      ) : props.formType === "newsletter" ? (
        <>
          {hasError ? (
            <div className="modal-wrapper">
              <ScrollAnimation
                animateIn="shake"
                offset={0}
                delay={0}
                className="modal"
              >
                {hasError}
              </ScrollAnimation>
            </div>
          ) : addedToNewsletter ? (
            <div className="modal-wrapper">
              <ScrollAnimation
                animateIn="bounceIn"
                offset={0}
                delay={0}
                className="modal"
              >
                Obrigado pela subscrição!
              </ScrollAnimation>
            </div>
          ) : (
            <form onSubmit={doNewsletter} method="POST" autoComplete="false">
              <ScrollAnimation
                className="form"
                animateIn="fadeIn"
                offset={0}
                delay={0}
                animateOnce={true}
              >
                <label htmlFor="email" className="visuallyhidden">
                  Email
                </label>
                <input
                  className="form-input"
                  type="email"
                  id="email"
                  placeholder="O seu e-mail"
                />
                <button className="form-button">
                  <i className="material-icons icon-align">send</i>
                </button>
              </ScrollAnimation>
            </form>
          )}
        </>
      ) : (
        ""
      )}
    </>
  )
}

export default Form
