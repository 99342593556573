import React from "react"
import PropTypes from "prop-types"
import "animate.css/animate.min.css"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Navbar from "./navbar"
import Footer from "./footer"
import "../styles/main.css"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: true,
    }
    this.openSidebar = this.openSidebar.bind(this)
    this.closeSidebar = this.closeSidebar.bind(this)
    this.removeHash = this.removeHash.bind(this)
  }

  openSidebar() {
    this.setState(state => ({ sidebarOpen: true }))
  }
  closeSidebar() {
    this.setState(state => ({ sidebarOpen: false }))
  }
  removeHash() {
    window.history.pushState(
      "",
      document.title,
      window.location.pathname + window.location.search
    )
  }

  render() {
    // eslint-disable-next-line
    const children = this.props.children
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Header openSidebar={this.openSidebar} />
            <Navbar
              isOpen={this.state.sidebarOpen}
              closeSidebar={this.closeSidebar}
              removeHash={this.removeHash}
            />
            <main>{this.props.children}</main>
            <Footer />
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
